/* ------------------------------------------------------------------------- */

body {
  background-color: #000;
  margin: 0;
  overflow-x: hidden;
  zoom: 90%;
}
button:focus { outline: none !important; }


/**
 * #ROOT
 * -----------------------------------------------------------------------------
 */

#root {
  font-size: 14px;
}

/**
 * END #ROOT
 * -----------------------------------------------------------------------------
 */


/**
 * HEADER
 * -----------------------------------------------------------------------------
 */

Header {
  background-color: unset !important;
  border: unset !important;
}

/**
 * END HEADER
 * -----------------------------------------------------------------------------
 */

/**
 * LOGO
 * -----------------------------------------------------------------------------
 */

#logo {}
.logo_link img {
  margin: 0px;
}

/**
 * END LOGO
 * -----------------------------------------------------------------------------
 */

/**
 * MENU
 * -----------------------------------------------------------------------------
 */

#menu {
  background: linear-gradient(
    to top,
    rgba(0,0,0,0.85) 20%,
    rgba(0,0,0,0.7)
  );
  border-bottom-left-radius: 9px;
  padding: 0;
  box-shadow: 0 0 8px #aaa;
  gap: 0;
  align-items: end;
}
#menu a {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#menu a:hover {
  background-color: rgba(0,0,0,0.3);
  text-shadow: 0 0 12px #fff;
}
#menu > a:first-child:hover { border-bottom-left-radius: 9px; }
  /* VISIVE FEEDBACK */
  #menu a:active { color: #aaa; }

#menu .mantine-Menu-dropdown {
  margin: -7px;
  background: linear-gradient(
    to bottom,
    rgba(0,0,0,0.5),
    rgba(50,50,50,0.85) 35%,
    rgba(0,0,0,0.8) 85%,
    #000 100%
  )
}
#menu .mantine-Menu-item:hover {
  color: #fff;
  background-color: rgba(0,0,0,0.5);
}
  /* VISIVE FEEDBACK */
  #menu .mantine-Menu-item:active { color: #ddd; }

#menu_docs {
  gap: 0;
  margin-bottom: -1px;
  z-index: 1;
}
#menu a.submenu svg,
#menu_docs a.submenu svg {
  transition: transform 150ms ease;
  transform: rotate(0deg);
}
#menu a.submenu:hover svg,
#menu_docs a.submenu:hover svg {
  transition: transform 150ms ease;
  transform: rotate(180deg);
}
#menu_lang-target {
  padding: 7px 10px;
}

/**
 * END MENU
 * -----------------------------------------------------------------------------
 */

/**
 * CONTENTS
 * -----------------------------------------------------------------------------
 */
#contents {
  /* width: calc(100% - 226px); */
  width: 100%;
}
#contents h1,
#contents h2,
#contents h3,
#contents h4,
#contents h5,
#contents h6 {
  margin: 0;
}
#calculated_address, #status_messages {
  position: relative;
}
#calculated_address {
  min-width: 80%;
}
#status_messages {
  min-width: 20%;
  vertical-align: bottom;
}
#mapsource_badge { cursor: crosshair; }
#point_btn img { object-position: "center center !important"; }
#private_point_btn img { object-position: "center -25px !important"; }
#sidebar {
  /* display: inline-block; */
}
/* #sidebar::after {
  background: linear-gradient(to bottom, #ff00002d, #eee, #0000ff3d);
  content: "";
  height: calc(100% - 120px);
  left: 225px;
  position: absolute;
  top: 121px;
  width: 1px;
} */

/**
 * END CONTENTS
 * -----------------------------------------------------------------------------
 */

/**
 * MANTINE
 * -----------------------------------------------------------------------------
 */
.mantine-Menu-dropdown {
  border-radius: 3px;
  min-width: 160px;
  z-index: 99999999999 !important;
}
.mantine-Menu-dropdown a, .mantine-Menu-dropdown button {
  border-radius: 3px !important;
}
.mantine-Modal-inner { padding: 20px; }
.mantine-Modal-modal {
  border-radius: 9px !important;
  box-shadow: 0px 0px 20px 6px rgba(255, 255, 255, 0.25) !important;
  font-size: 1.4rem;
  overflow: auto;
}
.mantine-Modal-header {
  border-bottom: #555 1px dotted;
  padding-bottom: 10px;
}
.mantine-Modal-title { margin-bottom: -10px; }
.mantine-Group-root {
	align-content: center;
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;
  display: flex;
}
#mapsource_input_area > .mantine-Input-wrapper .mantine-Input-icon {
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}
.mantine-Badge-inner { text-overflow: initial; }
.mantine-Group-root svg { vertical-align: middle; }
.mantine-Text-root {
  line-height: 1.4 !important;
  white-space: break-spaces;
}
.mantine-Text-root small {
  font-size: 12px;
  color: #ccc !important;
}
.small .mantine-Text-root { font-size: 11px !important; }
.mantine-InputWrapper-root { background-color: unset; }
.mantine-UnstyledButton-root {}
.mantine-TypographyStylesProvider-root {
  min-height: 150px;
}
/* .mantine-Input-wrapper { display: contents; } */
/* .mantine-Input-input {
  width: 200px;
  display: inline;
} */

/**
 * END MANTINE
 * -----------------------------------------------------------------------------
 */

/**
 * MAP
 * -----------------------------------------------------------------------------
 */
#map {
  cursor: crosshair;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

  /**
   * LEAFLET RULES
   * ---------------------------------------------------------------------------
   */
  .leaflet-container {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
  }
  .leaflet-control-container .leaflet-top.leaflet-left {
    position: absolute;
    top: 50%;
  }
  /* .leaflet-control-layers { background-color: #fff; } */
  .leaflet-control-layers.leaflet-control {
    border-radius: 4px;
    cursor: pointer !important;
    background-color: rgba(30, 30, 30, 0.8);
  }
  .leaflet-control-layers, .leaflet-bar {
    border: 2px solid rgba(255,255,255,0.2) !important;
  }
  .leaflet-control-layers label {
    color: #fff;
    cursor: pointer;
    padding: 5px 7.5px;
  }
  .leaflet-control-layers label + label {
    border-top: #888 1px dotted;
  }
  .leaflet-control-layers label:hover, .leaflet-touch .leaflet-bar a {
    background-color: rgba(30, 30, 30, 0.8);
    color: #fff;
  }
  .leaflet-control-layers label:hover {
    border-radius: 4px;
  }
  .leaflet-touch .leaflet-bar a {
    border-bottom: 1px solid #666;
  }
  .leaflet-touch .leaflet-bar a:hover {
    background-color: rgba(0,0,0,1);
    color: #fff;
  }
  .leaflet-control-layers label span input:checked {
    border: #ff0000 5px solid;
  }
  .leaflet-touch .leaflet-control-layers-toggle {
    width: 59px !important;
    height: 59px !important;
  }
  .leaflet-control-layers-selector {}
  /**
   * END LEAFLET RULES
   * -----------------------------------------------------------------------------
   */
/**
 * END MAP
 * -----------------------------------------------------------------------------
 */

/**
 * SEARCH INPUT
 * -----------------------------------------------------------------------------
 */
.search_input {
  background: transparent !important;
  box-shadow: 0 0 3px #aaa;
}
.search_input input {
  background-color: rgba(0, 0, 0, 0.85) !important;
}
/**
 * END SEARCH INPUT
 * -----------------------------------------------------------------------------
 */


/* --- */

.price {
  margin: 0 !important;
}
.short_url_text {
  color: "red" !important;
  user-select: none;
  white-space: nowrap;
}

.special-offer1 {
  animation: flicker 0.00194568s ease alternate infinite;
  /* animation-timing-function: steps(5, end); */
}
.special-offer2 {
  animation: flicker2 0.00194568s ease alternate infinite;
  /* animation-timing-function: steps(5, end); */
}
.special-offer3 {
  animation: flicker3 0.00194568s ease alternate infinite;
  /* animation-timing-function: steps(5, end); */
}
@keyframes flicker {
	0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
		opacity: .99;
		text-shadow: -1px -1px 0 $outline, 1px -1px 0 $outline, -1px 1px 0 $outline, 1px 1px 0 $outline, 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00, 0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
	}
	20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
		opacity: 0.4;
		text-shadow: none;
	}
}
@keyframes flicker2 {
	0%, 20.999%, 23%, 63.999%, 65%, 65.999%, 71%, 100% {
		opacity: .99;
		text-shadow: -1px -1px 0 $outline, 1px -1px 0 $outline, -1px 1px 0 $outline, 1px 1px 0 $outline, 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00, 0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
	}
	21%, 22.999%, 64%, 64.999%, 66%, 70.999% {
		opacity: 0.4;
		text-shadow: none;
	}
}
@keyframes flicker3 {
	0%, 21.999%, 24%, 64.999%, 66%, 66.999%, 72%, 100% {
		opacity: .99;
		text-shadow: -1px -1px 0 $outline, 1px -1px 0 $outline, -1px 1px 0 $outline, 1px 1px 0 $outline, 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00, 0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
	}
	22%, 23.999%, 65%, 65.999%, 67%, 71.999% {
		opacity: 0.4;
		text-shadow: none;
	}
}
